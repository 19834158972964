<template>
	<div class="bind-page ls-flex-ajc">
		<div class="bind-box text-center">
			<h1>
				BIND NFT ID
				<span v-if="!showBindBtn">{{nftId}}</span>
			</h1>
			<div v-if="showBindBtn">
				<el-input v-if="!inviteId" class="mt-4" v-model="nftId" placeholder="Bind address to NFT ID" />
				<div class="invite-id mt-4" v-else>{{inviteId}}</div>
				<div class="bind-btn ls-bg mt-5 ls-flex-ajc" @click="bindNftId()">Confirm</div>
			</div>
		</div>
	</div>
	
	<comModal :showState="showModalState" :State="stateNum" :StateInfo="StateInfo" :tHash="tHash"></comModal>
</template>

<script setup>
	import { ref,h,watch,onMounted } from 'vue';
	import {useStore} from 'vuex';
	import { relationAddr,stackeAddr } from '@/utils';
	import { relationAbi } from '@/utils/relationAbi.json';
	import {stackeAbi} from '@/utils/stackeAbi.json';
	import Web3 from "web3";
	import { ElMessage} from 'element-plus';
	import {useRoute} from 'vue-router';
	import comModal  from "@/components/com-modal/com-modal.vue";
	
	const showModalState = ref(false);
	const StateInfo = ref('');
	const stateNum = ref(0);
	const tHash = ref('');
	
	const stakeBalance = ref(0);
	
	const route = useRoute();
	
	const inviteId = ref('');
	const nftId = ref('');
	const showBindBtn = ref(true);
	
	if(route.params.id){
		inviteId.value = route.params.id;
		nftId.value = route.params.id;
	}
	
	const store = useStore();
	
	import {provider} from "@/utils/provider.js";
	
	var web3js;
	if(store.state.isNet == 1){
		if (typeof window.ethereum === "undefined") {
			//没安装MetaMask钱包进行弹框提示
			web3js = new Web3();
		} else {
			web3js = new Web3(ethereum);
		}
	}
	else{
		web3js = new Web3(provider);
	}
	
	const relationContract = new web3js.eth.Contract(relationAbi,relationAddr);
	const stackeContract = new web3js.eth.Contract(stackeAbi, stackeAddr);
	
	const initData = ()=>{
		if(store.state.account){
			relationContract.methods.inviteNode(store.state.account).call().then((res)=>{
				if(res!=0){
					showBindBtn.value = false;
					nftId.value = res;
				}
			})
			
			// stakeBalances
			stackeContract.methods.stakeBalances(store.state.account).call().then((res) => {
				if (res > 0) {
					stakeBalance.value = res;
				}
			
			});
		}
	}
	onMounted(()=>{
		initData();
	})
	
	watch(store.state,(news,olds)=>{
		initData();
	})
	
	const bindNftId = ()=>{
		if(!nftId.value){
			ElMessage('Enter bind address of NFT ID');
			return false;
		}
		
		if(stakeBalance.value>0){
			ElMessage('You can`t bind. You`ve already staked');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Bind';
		stateNum.value = 1;
		
		relationContract.methods.bind(nftId.value).send({
			from:store.state.account
		}).then((res)=>{
			if(res){
				tHash.value = res.transactionHash;
				stateNum.value = 2;
				
				showBindBtn.value = false;
			}
		},(err)=>{
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}
	
</script>

<style scoped>
	.bind-page{
		height: inherit;
		background-image:url('../../assets/img/icon/nft_03.png');
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.bind-box{
		width: 600px;
		background: #260000;
		border-radius: 15px;
		padding: 42px 25px;
	}
	.bind-box h1{
		color: #fff;
	}
	.bind-btn{
		width: 200px;
		height: 42px;
		margin: 0 auto;
		border-radius:21px;
		font-weight: 500;
		cursor: pointer;
	}
	.invite-id{
		width:80%;
		height:36px;
		line-height:36px;
		margin:0 auto;
		background-color:#fff;
		border-radius:8px;
		font-size:20px;
	}
	@media (max-width:768px) {
		.bind-page{
			background-image:url('../../assets/img/icon/nft_03.png');
		}
		.bind-box{
			width: 350px;
		}
		.bind-box h1{
			font-size:28px;
		}
	}
</style>